* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
div {
    cursor: default;
}

.success {
    color: #20c997;
}

svg {
    width: 30px !important;
}

.error {
    color: #ff0000;
}

.hidden {
    display: none;
}

.activeBorder {
    border: 1px solid #FF6700 !important;
}

.active {
    color: #FF6700;
}

html,
body {
    width: 100%;
    height: 100%;
    padding: 6px;
    display: grid;
    background-color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}

.iconContainer svg {
    width: 30px;
}

.dashboardWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px;
    position: relative;
}

.dashboardLeft_wrapper {
    flex: 0.3;
    border-right: 1.5px solid #f7f7f7;
}


.mainWrapper {
    gap: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: none;
    background-color: #eef1f6;
    border-top-right-radius: 30px;
    position: relative;
}

.mainWrapper .centerContainer {
    width: 100%;
    height: 98vh;
    position: relative;
    overflow-y: scroll;
    background-color: #f4f8fb;
    border-top-right-radius: 30px;
}
.dashboardCenter {
    padding: 10px;
}

.mainWrapper .asideBar {
    top: 0px;
    z-index: 1;
    flex: 0.3;
    height: 100%;
    position: sticky;
    overflow-y: auto;
}

.modalContainer {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4);
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
  }
.asideBar .lgScreen {
    padding: 5px;
}
.mediaScreem {
    display: none;
}

.mainItems {
    display: grid;
    border-radius: 30px;
    background-color: #eef1f5;
    grid-template-columns: 11fr 4fr;
}

.mainItemsActive {
    display: grid;
    border-radius: 30px;
    background-color: #eef1f5;
    grid-template-columns: 1fr;
}

.dashboardLeft_wrapper {
    padding: 14px;
}
.mainWrap {
    background-color: #eef1f6;
    border-top-right-radius: 30px;
}
.dashboardCenter_wrapper {
    flex: 1;
}

.dashboardCenter_wrapper,
.dashboardRight_wrapper,
.airtimeContWrap,
.dataContWrap {
    color: #002147;
    position: relative;
}

.dashboardRight_wrapperActive {
    display: none;
}

.dashboardLeft_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboardLeft {
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboardLeft .optionsWrap {
    font-size: medium;
}

.dashboardLeft .logOutBtn {
    background: #FF6700;
}

.logoo {
    width: 200px;
    height: 50px;
}

.logoo img {
    width: 100%;
}

.navOptions {
    margin: 20px auto;
}
.navOptions a {
    font-size: medium;
}
.optionIcon {
    width: 25px;
    height: 25px;
    color: #002147;
}

.optionsWrap {
    gap: 4px;
    display: grid;
    padding: 12px 0;
    cursor: pointer;
    color: #002147;
    align-items: center;
    text-decoration: none;
    grid-template-columns: 1fr 7fr;
}

.logOutWrapper {
    left: 0%;
    bottom: 2%;
    width: 93%;
    position: absolute;
    text-align: center;
}

.logOutBtn {
    padding: 15px;
    color: #fff;
    cursor: pointer;
    border-radius: 35px;
    background-color: #002147;
    transition: 0.4s all ease-in;
    -webkit-transition: 0.4s all ease-in;
}

.logOutBtn:hover {
    background-color: #FF6700;
}
.rightWrap {
    background-color: #eef1f6;
    padding: 5px 7px;
    flex: 0.3;
    border-top-right-radius: 30px;
    z-index: 2;
}

.dashboardCenter_wrapper,
.airtimeContWrap,
.dataContWrap {
    width: 95%;
    height: 100vh;
    margin: auto;
    padding: 10px;
    position: sticky;
    overflow-y: auto;
    border-radius: 25px;
    background-color: #f5f8fa;
}

.searchRow {
    top: 0;
    bottom: 0;
    z-index: 22;
    display: flex;
    position: sticky;
    align-items: center;
    background-color: #f4f8fb;
    justify-content: space-between;
}

.airtimeMain,
.dataMain {
    display: flex;
    font-size: 13px;
    font-weight: 700;
    color: #002147;
    margin-top: 5px;
    gap: 5px;
    justify-content: space-between;
}
.airtimeMain .airtimeLeftWrap {
    flex: 0.3;
}
.airtimeLeftWrap {
    padding: 5px;
}

.airtimeMain,
.airtimeRight {
    flex: 1;
}

.airtimeRight {
    height: auto;
    padding: 5px 0;
    margin: 0 0 30px 0;
}

.productContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    text-align: center;
    gap: 5px;
}

.profDetails {
    width: 60%;
    margin: 20px auto;
}

.profDetails>div {
    margin-bottom: 10px;
}

.transactionMain {
    gap: 10px;
    display: grid;
    font-size: 13px;
    font-weight: 700;
    color: #002147;
    margin: 20px auto;
    grid-template-columns: repeat(4, 1fr);
}

.transactItems {
    cursor: pointer;
    padding: 30px 15px;
    border-radius: 12px;
    background-color: #fff;
    transition: 0.2s all ease-in;
    box-shadow: 0px 3px 5px #c9cfd4;
    -webkit-transition: 0.3s all ease-in;
}

.transactItems:hover {
    transform: scale(1.001);
}

.transactItemsActive {
    color: #ff0000;
    cursor: pointer;
    padding: 30px 15px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 3px 5px #ff9f9f;
}

table {
    width: 100%;
    font-size: 13px;
    text-align: left;
    border-collapse: collapse;
}

thead {
    background-color: #EEF1F5;
}

th,
td {
    padding: 15px
}

tr:nth-child(even) {
    background-color: #EEF1F5;
}

.pendStatus {
    color: #88da88;
}

.completedStatus {
    color: #00cc00
}

.failedStatus {
    color: #ff0000
}

.activeTxt {
    top: 1.4vw;
    color: #ff0000;
    position: absolute;
}

.dataOpt {
    margin-bottom: 10px;
}

.airtimeLeft,
.dataLeft {
    display: grid;
    padding: 8px;
    cursor: pointer;
    overflow: hidden;
    font-size: smaller;
    margin-bottom: 13px;
    border-radius: 15px;
    background-color: #eef1f5;
    transition: 0.3s all ease-in;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 2px 4px #c9cfd4;
    -webkit-transition: 0.3s all ease-in;
}

.airtimeLeft {
    width: 100%;
}

.airtimeLeft:hover,
.dataLeft:hover {
    color: #FF6700;
    transform: scale(1.01);
}

.rechargeImg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #fff;
}

.rechargeImg img {
    width: 100%;
}

.airtimeLCont,
.dataLCont {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 3fr;
}

.cardDetails {
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.aicone {
    display: grid;
    align-items: center;
    border-left: 1px dashed rgb(189, 189, 189);
}

.selectPack,
.passUpdate {
    margin-bottom: 10px;
}

.rechargeIcon {
    text-align: center;
    margin: 0 auto
}

.userIptContent {
    gap: 10px;
    display: grid;
    margin-top: 12px;
}

.proBtn {
    margin: 14px auto;
}

.proBtn button {
    width: 100%;
    border: none;
    outline: none;
    color: #002147;
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #c9cfd4;
    transition: 0.4s all ease-in-out;
    -webkit-transition: 0.4s all ease-in-out;
}

.proBtn button:hover {
    color: #fff;
    transform: scaleX(0.99);
    background-color: #002147;
}

.searchWrap {
    width: 80%;
    margin: auto;
    border-radius: 15px;
}

.searchWrap input {
    width: 100%;
    outline: none;
    border: none;
    padding: 12px;
    margin: auto;
    border-radius: 15px;
    background-color: transparent;
    box-shadow: 0 2px 2px #d7dce4;
}

.componentName {
    font-size: 16px;
    font-weight: 700;
    position: relative;
}

.orangeBorder,
.orangeBorder2 {
    top: 1.3vw;
    width: 100%;
    z-index: 3;
    position: absolute;
    background-color: #FF6700;
}

.orangeBorder2 {
    padding: 1.5px;
}

.orangeBorder {
    padding: 1.2px;
}

.notificationWrap {
    display: flex;
    color: #002147;
    font-weight: 800;
    align-items: center;
    justify-content: space-between;
}

.welcomeImg {
    display: grid;
    text-align: right;
}

.welcomeImgCont {
    width: 45%;
    margin: auto;
}

.welcomeImgCont img {
    width: 100%;
}

.welcomeRow {
    display: grid;
    padding: 15px;
    margin: 14px auto;
    border-radius: 15px;
    align-items: center;
    background-color: #eef1f6;
    grid-template-columns: 3fr 2fr;
}

.welcomeTxt {
    line-height: 30px;
}

.profileGrowth {
    padding: 15px;
    width: 100%;
    margin-top: 16px;
    border-radius: 15px;
    background-color: #eef1f5;
}

.userDropDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userDropDetails svg {
    width: 30px;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.userDropDetails svg:hover {
    color: #20c997;
    transform: scale(1.2);
}

.userIcon {
    width: 47px;
    height: 47px;
}

.userIcon img {
    width: 100%;
}

.toggleBtn {
    top: 1.8vw;
    z-index: 3;
    width: 23px;
    right: 1.5%;
    cursor: pointer;
    position: absolute;
}

.toggleBtnRes {
    top: 1.8vw;
    z-index: 3;
    width: 23px;
    right: 1.5%;
    cursor: pointer;
    position: absolute;
    display: none;
    border: 1px solid #ff0000;
}

.toggleBtnActive {
    top: 1.4vw;
    z-index: 3;
    width: 23px;
    right: 3%;
    cursor: pointer;
    position: absolute;
}

.toggleBtnResActive {
    top: 1.4vw;
    z-index: 8;
    width: 23px;
    right: 3%;
    display: none;
    cursor: pointer;
    position: absolute;
}

.notifIconActive {
    position: absolute;
}

.firstLine,
.secondLine,
.thirdLine {
    background-color: #000;
    padding: 1.3px;
}

.dataOpt {
    gap: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.dataOpt .dataItems {
    flex: 1;
}

.dataItem {
    width: 100%;
    padding: 18px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid gray
}

.totalDeb {
    padding: 20px 15px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 3px 5px #c9cfd4;
}

.dateWrapper {
    width: 100%;
}

.calennn {
    border: 1px solid #002147;
    border-radius: 15px;
    padding: 10px 0;
    margin: 0;
    width: 100%;
}

.credit {
    color: #23E5A4;
}

.debit {
    color: #ff0000;
}

.totalDeb h4 {
    margin-top: 10px;
}

.secondLine,
.thirdLine {
    margin-top: 3px;
}

.firstLine {
    width: 100%;
}

.secondLine {
    width: 92%;
}

.thirdLine {
    width: 82%;
}

.amtDetails {
    gap: 13px;
    display: grid;
    margin: 13px auto;
}

.transactionCards {
    gap: 10px;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.transactionCards>div {
    flex: 1;
}

.userIpt {
    gap: 10PX;
    display: flex;
    justify-content: space-between;
}

.userIpt input,
select {
    width: 100%;
    outline: none;
    padding: 15px;
    color: #002147;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: 0.3s all ease-in;
    background-color: transparent;
    -webkit-transition: 0.3s all ease-in;
}

.userIpt input:focus,
select:focus {
    box-shadow: 3px 6px 6px -6px rgba(0, 33, 71, 0.3);
}

.userIpt input::placeholder {
    color: rgba(0, 33, 71, 0.7);
}

.effect {
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}
.btn:hover {
    transform: scale(1.2);
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.toolTip {
    top: 0;
    right: 0;
    z-index: 9999;
    padding: 15px;
    color: white;
    margin-top: 1%;
    display: flex;
    width: 20%;
    justify-content: space-between;
    font-size: small;
    position: fixed;
    margin-right: 15px;
    border-radius: 10px;
    animation: forwards;
    align-items: center;
    animation-name: Tooltip;
    animation-duration: 0.5s;
    background-color: #000;
}

@keyframes Tooltip {

    from {
        background-color: white;
    }

    to {
        background-color: #000;
    }
}

.toolTip>div {
    width: 30px;
    height: 30px;
    display: flex;
    margin-left: 10px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-color: rgb(39, 38, 38)
}

.toolTip>div:hover {
    color: #ff0000;
}

/* .toolTip svg {
    width: 35px;
    height: 35px;
} */

.inputContainer {
    gap: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.inputContainer>div {
    flex: 1;
    margin-bottom: 20px;
}

.inputContainer span {
    margin-bottom: 10px;
}

.inputContainer>div textarea {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #e6e6e6;
}

.inputContainer>div select {
    width: 100%;
    outline: none;
    border-radius: 5px;
    background: #FFF;
}

.inputContainer input {
    width: 100%;
    margin: auto;
    outline: none;
    font-size: medium;
    border-radius: 5px;
    background: #FFF;
    border: 0.5px solid #6666;
}

.inputContainer select {
    height: 50px;
    width: 90%;
    margin: auto;
    font-size: medium;
    border-radius: 5px;
    border: 0.5px solid #6666;
}

.inputContainer button {
    width: 11vw;
    color: white;
    border: none;
    margin: auto;
    padding: 0.9vw;
    font-size: large;
    margin-top: 2.2vw;
    margin-bottom: 0.5vw;
    border-radius: 10px;
    background-color: #002147;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
}

.popOverContainer {
    z-index: 200;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
}


.memberSignupContainer {
    flex: 1;
    width: 90%;
    padding: 20px;
    display: flex;
    margin: auto;
    border-radius: 10px;
    background: inherit;
    align-items: center;
    flex-direction: column;
}

.memberSignupContainer a {
    margin: 10px;
    color: orange;
}

.memberSignupContainer h3 {
    padding: 10px;
    margin-bottom: 10px;
    font-size: x-large;
}

.memberSignupContainer svg {
    width: 80px;
}

.memberAvatar {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    justify-content: center;
    font-size: 1vw;
}

.shadow {
    box-sizing: border-box;
    box-shadow: 0px 2px 2px 0px #dadada;
}

.popOver {
    width: 80%;
    margin: auto;
    height: 80%;
    display: flex;
    border-radius: 10px;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
}

.popOverLarge {
    width: 80%;
    margin: auto;
    height: 80%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
}

.popOverMedium {
    width: 50%;
    margin: auto;
    height: 80%;
    display: flex;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
}

.popOverSmall {
    width: 25%;
    margin: auto;
    height: 80%;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
}

.popOverXsmall {
    width: 20%;
    margin: auto;
    height: 10%;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
}

@keyframes Modal {
    from {
        height: 0px;
    }

    to {
        height: 80%;
    }
}

.flashMsgContainer {
    z-index: 200;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* -webkit-filter: blur(8px); */
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
}

.flashMsgContainer .flashMsgHeader {
    color: #FFF;
    display: flex;
    font-size: large;
    padding-left: 10px;
    padding-right: 10px;
    background: #F00;
    align-items: center;
    justify-content: center;
}

.flashMsgContainer .flashMsgFooter {
    gap: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flashMsgContainer .flashMsgFooter button {
    color: #000;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    font-size: medium;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
}

.flashMsgContainer .flashMsgFooter button:nth-child(2) {
    width: 100px;
    border: 0.5px solid #F00;
}


.flashMsgContainer .flashMsgFooter button:hover {
    color: #F00;
    transform: scale(1.2);
}

.flashMsgContainer .flashMsgBody {
    flex: 1;
    display: flex;
    overflow: auto;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flashMsgContainer .flashMessage {

    gap: 10px;
    padding: 10px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1.5px solid #ededed;
    border-bottom: 1.5px solid #ededed;
}


.flashMsgContainer .msgIcon {
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.flashMsgContainer svg {
    width: 40px;
}

.flashMsgContainer .flashMessage .info {
    font-size: medium;
    text-align: left;
}

.popOverCloseBtn {
    width: 30px;
    height: 30px;
    padding: 2px;
    color: #F00;
    display: flex;
    margin-top: 5px;
    border-radius: 50%;
    background: #ffff;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 2px 0px #666;
}

.inputContainer {
    gap: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.inputContainer>div {
    flex: 1;
    margin-bottom: 20px;
}

.inputContainer span {
    margin-bottom: 10px;
}

.inputContainer>div textarea {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #e6e6e6;
}

.inputContainer>div select {
    width: 100%;
    outline: none;
    border-radius: 5px;
    background: #FFF;
}

.inputContainer input {
    width: 100%;
    margin: auto;
    outline: none;
    padding: 15px;
    font-size: medium;
    border-radius: 5px;
    background: #FFF;
    border: 0.5px solid #6666;
}

.inputContainer select {
    height: 50px;
    width: 90%;
    margin: auto;
    font-size: medium;
    border-radius: 5px;
    border: 0.5px solid #6666;
}

@media only screen and (max-width: 600px) {

    .popOver,
    .popOverSmall,
    .popOverMedium,
    .popOverLarge {
        width: 90%;
        overflow: auto;
        height: 80vh !important;

    }

    .popOver .cardData img,
    .popOverSmall .cardData img,
    .popOverMedium .cardData img,
    .popOverLarge .cardData img {
        width: 100px;
        height: 100px;
    }

    .popOver span,
    .popOverSmall span,
    .popOverMedium span,
    .popOverLarge span {
        font-size: small;
    }

    .popOver .inputContainer,
    .popOverSmall .inputContainer,
    .popOverMedium .inputContainer,
    .popOverLarge .inputContainer {
        flex-direction: column;
    }

    .popOver .inputContainer div,
    .popOverSmall .inputContainer div,
    .popOverMedium .inputContainer div,
    .popOverLarge .inputContainer div {
        margin-bottom: 5px;
    }

    .popOver .inputContainer div ::placeholder,
    .popOverSmall .inputContainer div ::placeholder,
    .popOverMedium .inputContainer div ::placeholder,
    .popOverLarge .inputContainer div ::placeholder {
        color: #666;
        opacity: 0.5;
        font-size: small;
    }

    .popOver .inputContainer button,
    .popOverSmall .inputContainer button,
    .popOverMedium .inputContainer button,
    .popOverLarge .inputContainer button {
        width: 200px;
        padding: 15px;
        margin-top: 15px;
        font-size: large;
    }
}

.noRecord {
    color: #ff0000;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;
    flex-direction: column;
    align-items: center;
}

.norec_img_cont {
    width: 40%;
    height: 70%;
}

.norec_img_cont img {
    width: 100%;
    height: 100%;
}

.noRecord p {
    font-size: xx-large;
    color: #ff0000 !important;
}

.receiptContainer {
    gap: 20px;
    display: flex;
    font-size: medium;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.receiptContainer .receiptIcon {
    width: 60px;
    height: 60px;
    border-radius: 40px;
}

.receiptLogo {
    width: 80%;
    margin: auto;
    object-fit: contain;
}

.all {
    border-left: 4px gray solid;
}

.pending {
    border-left: 4px #002147 solid;
}

.completed {
    border-left: 4px #20c997 solid;
}

.canceled {
    border-left: 4px #F00 solid;
}

.totalDeb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cardContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.cardHeader img {
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 16px;
    box-shadow: 0px 3px 5px #c9cfd4;
}

.cardHeader h3 {
    padding: 8px;
}

.cardFooter {
    display: flex;
    margin-top: 16px;
    justify-content: center;
}

.cardFooter svg,
.cardHeader svg {
    font-size: 36px;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.cardFooter svg:hover {
    color: #FF6700
}

.padding {
    padding: 18px;
}

.cardHeader button {
    width: 200px;
    margin-top: 22px;
    background: #002147;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
}

.cardHeader button:hover {
    color: #FFF;
    background: #FF6700;
}

.cardContainer .cardData {
    width: 90%;
    margin: auto;
    font-size: medium;
}

.cardContainer .cardData>div {
    padding: 6px;
    display: flex;
    justify-content: space-between;
}

.drawerContainer {
    z-index: 222;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background: #f7f7f7b1;
}

.drawerContainer .drawerBody {
    width: 70%;
    color: white;
    height: 100vh;
    padding: 10px;
    position: sticky;
    overflow-y: auto;
    font-size: small;
    background: #002147;
    animation-name: Drawer;
    animation-duration: 0.5s;
    transition: ease-in all 0.5s;
    animation-fill-mode: forwards;
    -webkit-transition: ease-in all 0.5s;
}

@keyframes Drawer {
    from {
        width: 0px;
    }

    to {
        right: 70%;
    }
}

.dataLeftWrap {
    flex: 0.3;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto !important;
}

@media only screen and (max-width: 820px) {

    .popOver,
    .popOverSmall,
    .popOverMedium,
    .popOverLarge {
        width: 90%;
        overflow: auto;
        height: 100vh !important;

    }

    .popOver span,
    .popOverSmall span,
    .popOverMedium span,
    .popOverLarge span {
        font-size: large;
    }

    .popOver .inputContainer,
    .popOverSmall .inputContainer,
    .popOverMedium .inputContainer,
    .popOverLarge .inputContainer {
        flex-direction: column;
    }

    .popOver .inputContainer div,
    .popOverSmall .inputContainer div,
    .popOverMedium .inputContainer div,
    .popOverLarge .inputContainer div {
        margin-bottom: 5px;
    }

    .popOver .inputContainer div ::placeholder,
    .popOverSmall .inputContainer div ::placeholder,
    .popOverMedium .inputContainer div ::placeholder,
    .popOverLarge .inputContainer div ::placeholder {
        color: #666;
        opacity: 0.5;
        font-size: medium;
    }

    .popOver .inputContainer input,
    .popOverSmall .inputContainer input,
    .popOverMedium .inputContainer input,
    .popOverLarge .inputContainer input {
        height: 50px;
    }

    .popOver .inputContainer .select_opt,
    .popOverSmall .inputContainer .select_opt,
    .popOverMedium .inputContainer .select_opt,
    .popOverLarge .inputContainer .select_opt {
        height: 50px;
    }

    .popOver .inputContainer button,
    .popOverSmall .inputContainer button,
    .popOverMedium .inputContainer button,
    .popOverLarge .inputContainer button {
        width: 200px;
        padding: 15px;
        margin-top: 15px;
        font-size: large;
    }

    
}