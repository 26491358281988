
* {
    margin: 0;
    padding: 0;
    cursor: pointer;
    scroll-behavior: smooth;
  }
  a {
    color: black;
    font-size: 20px;
    text-decoration: none;
  }
  
  ul li {
    list-style: none;
    text-decoration: none;
  }
  .success {
    color: #20c997;;
  }
  .error{
    color: red;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-float infinite 3s ease-in-out;
    }
    
  }
  .appBackDrop {
    z-index: 500;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* -webkit-filter: blur(8px); */
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4)
  
  }
  .appBackDrop img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
  
  .app__Container {
    flex: 1;
    height: 100vh;
    display: flex;
    background: #ededed;
    flex-direction: row;
  }
  
  .app__SideBar {
    top: 0;
    z-index: 22;
    flex: 0.3;
    height: 100vh;
    display: flex;
    position: sticky;
    background: white;
    overflow: auto;
    flex-direction: column;
  }
  
  .app__SideBar .sideBar__logo, .profile {
    width: 95%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
  
  }
  
  .sideBar__logo svg{
    width: 30px;
    height: 30px;
    margin-top: -10px;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .sideBar__logo svg:hover{
    color: red;
    transform: scale(1.2);
  }
  
  .profile {
    border-bottom: 1.5px solid #ededed;
  }
  
  .profile .info {
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .profile .avatar img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: contain;
  }
  
  .sideBar__Menu {
    flex: 1;
    width: 90%;
    margin: auto;
    background: #FFFF;
  }
  
  .sideBar__Menu .roles {
    display: flex;
    align-items: center;
  }
  
  .sideBar__Menu .roles svg {
      width: 20px;
      height: 20px;
  }
  
  .sideBar__Menu .roles li {
    flex: 1;
  }
  
  .sideBar__Menu .subRoles {
    background: #ededed;
    position: relative;
    font-size: small;
    margin-left: 25px;
    background: inherit;
    animation-name: SubRoles;
    animation-duration: 0.5s; 
    transition: ease-in all 0.5s;
    animation-fill-mode: forwards;
    -webkit-transition: ease-in all 0.5s;
  }
  
  @keyframes SubRoles {
    from {left: 0px;}
    to {left: 20px;}
  }
  .sideBar__Menu .subRoles li {
    font-size: medium;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    
  }
  
  .sideBar__Menu ul li {
    padding: 10px;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .sideBar__Menu ul li:hover {
    color: red;
    /* background: #ededed; */
  }
  .sideBar__Menu ul a:hover  {
    transition: all ease-in 0.3s;
    color: red;
    background: #ededed;
  }
  
  .app__SideBar .sideBar__logo {
    top: 0;
    z-index: 225;
    margin: 10px;
    background: inherit;
    position: sticky;
    border-bottom: 1.5px solid #ededed;
  }
  .app__SideBar .sideBar__logo div {
    flex: 1;
    display: flex;
    margin-right: 20px;
    justify-content: flex-end;
  
  }
  .app__SideBar .sideBar__logo img {
    object-fit: contain;
  }
  
  .app__SideBar .logout {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .app__SideBar .logout button {
    width: 60%;
    color: #FFF;
    border: none;
    margin: auto;
    padding: 15px;
    font-size: 18px;
    border-radius: 15px;
    background: red;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  
  }
  
  .app__SideBar button:hover {
    transform: scale(1.2);
  }
  
  .app__Container .app__Feed {
    flex: 1;
    height: 100vh;
    position: sticky;
    overflow: auto;
    background: #ededed;
  }
  
  .app__Header {
    top: 0;
    z-index: 222;
    display: flex;
    padding: 14px;
    position: sticky;
    flex-direction: row;
    background: white;
    justify-content: space-between;
  }
  .app__Header .headerTitle {
    display: flex;
    align-items: center;
  }
  
  .app__Header .headerLeftMenu {
    flex: 1;
    padding: 4px;
    display: flex;
    justify-content: flex-end;
  }
  
  .app__Header .headerLeftMenu .headerLinksContainer{
    display: flex;
    align-items: center;
  }
  .headerLinksContainer:hover svg{
    color: #20c997;
  }
  
  .headerLinksContainer .linkDropMenu{
    top: 80px;
    z-index: 22;
    padding: 10px;
    font-size: medium;
    position: absolute;
    background: #FFFF;
    border-radius: 5px;
  }
  
  .linkDropMenu p {
    display: flex;
    padding: 1px;
    font-size: large;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1.5px solid #ededed;
  }
  .headerLinksContainer:hover .linkDropMenu{
    display: inline;
    
  }
  .headerLinksContainer ul {
    padding: 5px;
    padding-left: 6px;
    padding-right: 6px;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;;
  }
  
  .headerLinksContainer ul:hover {
    color: #20c997;
   
    background: #ededed;
  
  }
  /* login */
  
  .logIn__Feed {
    flex: 1;
    height: 95%;
    display: flex;
    margin: auto;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .shadow {
    box-sizing: border-box;
    box-shadow: 0px 2px 2px 0px #dadada;
  }
  
  .logIn__Feed .logIn__FormContainer {
    width: 500px;
    padding: 15px;
    margin: auto;
    border-radius: 10px;
  }
  
  .logIn__FormContainer .logo {
    width: 90%;
    display: flex;
    padding: 20px;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .logIn__FormContainer .logo h2 {
    margin-top: 10px;
  }
  
  .logIn__FormContainer .logo img {
    height: 100px;
    width: 90%;
    margin: auto;
    object-fit: contain;
  }
  
  
  .logIn__Feed .logIn__FormContainer .input__Box, input {
    width: 100%;
  }
  
  .logIn__Feed .logIn__FormContainer .input__Box {
    margin-bottom: 20px;
  }
  
  .logIn__Feed .logIn__FormContainer .input__Box input{
    width: 100%;
    border: none;
    height: 45px;
    outline: none;
    margin: auto;
    padding-left: 5px;
    border-radius: 10px;
    
  }
  
  textarea {
    border-radius: 10px;
  }
  
  .logIn__Feed .logIn__FormContainer .input__Box button {
    width: 50%;
    color: #FFFF;
    margin: auto;
    display: flex;
    outline: none;
    border: none;
    padding: 15PX;
    font-size: large;
    background: #F00;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .logIn__Feed .logIn__FormContainer .input__Box button:hover {
    transform: scale(1.2);
  
  }
  
  .statistics {
    display: flex;
    width: 400px;
    padding: 10px;
    background: #FFF;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
  }
  
  .statistics .information {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  
  
  /* tooltip */
  .app__tooltip {
    color: #fff;
    z-index: 222;
    margin: 15px;
    padding: 15px;
    margin-top: 6%;
    /* min-width: 200px; */
    position: absolute;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    animation-name: Tooltip;
    animation-duration: 0.5s; 
    transition: ease-in all 0.5s;
    animation-fill-mode: forwards;
    -webkit-transition: ease-in all 0.5s;
  }
  
  @keyframes Tooltip {
    from {right: 0px;}
    to {right: 200px;}
  }
  .app__tooltip p {
    font-size: 20px;
    font-weight: 500px;
  }
  
  /* Funding */
  
  .fundingContainer {
    width: 100%;
    display: flex;
    position: relative;
    align-self: center;
    flex-direction: column;
  }
  
  .flowContainer {
    height: 65vh;
    position: sticky;
    overflow: auto;
  }
  
  
  .fundingContainer table {
    width: 100%;
  }
  
  .fundingContainer table thead th div {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    background: white;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
   
  }
  
  .fundingContainer table thead th select, input {
    width: 90%;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: white;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
   
  }
  
  .activeTab {
    color: #FFF;
    background: red !important;
  }
  
  /* transaction table */
  
  .transaction_table{
    width: 100%;
    text-align: left;
  }
  
  .commisionTable {
    width: 100%;
  }
  .tableTotal {
    width: 100%;
    padding: 10px;
    display: flex;
    color: #20c997;
    align-items: center;
    justify-content: center;
    background: #edededed;
  }
  
  .transaction_table .table_ActionContainer{
    display: flex;
    border-radius: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
   
  
  }
  
  .transaction_table .table_ActionContainer img{
    object-fit: contain;
    width: 35px;
    height: 35px;
  }
  
  .dataPopup {
    z-index: 22;
    right: 0;
    background-color: red;
    margin-right: 90px;
    position: absolute;
    background: #ededed;
    animation: popUp;
    animation-duration: 0.5s; 
    transition: ease-in all 0.5s;
    animation-fill-mode: forwards;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #dadada;
  }
  .dataPopup ul {
    padding: 15px;
  }
  
  @keyframes popUp {
    from {width: 0px;}
    to {width: 500px;}
   
  }
  .dataPopup .createDataInfo {
    width: 100%;
    display: flex;
    padding: 5px;
    
    flex-direction: row;
    justify-content: space-between;
  }
  .dataPopup .createDataInfo p:nth-of-type(2) {
    flex: 1;
    right: 0;
    display: flex;
    justify-content: end;
  }
  
  .table_ActionContainer .dataPopup div:nth-of-type(1) {
    right: 0;
    width: 20px;
    padding: 10px;
    position: absolute;
    background: red;
  }
  
  .transaction_table tr {
    height: 45px;
  }
  
  .closePopup {
    color: red;
    font-size: 36px;
  }
  
  .transaction_table tr:hover{
   background: #edededed;
   transition: all ease-in 0.3s;
   -webkit-transition: all ease-in 0.3s;
  }
  
  .trHoverActive { 
    background: #dedede;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;  
  }
  
  .pagenation_container{
    gap: 10px;
    padding: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    background: #dedede;
    transition: all ease-in 0.5s;
    -webkit-transition: all ease-in 0.5s;
    justify-content: space-between;
  }
  
  .customTitle {
    margin-right: 10px;
  }
  .customTitle:hover {
    color: #20c997;
    transition: ease-in all 0.5s;
    -webkit-transition: all ease-in 0.5s;
  }
  
  .pagenation_input_Container{
    display: flex;
    gap: 15px;
    align-items: center;
    animation: customSearch;
    animation-duration: 0.5s; 
    transition: ease-in all 0.5s;
    animation-fill-mode: forwards;
    -webkit-transition: ease-in all 0.5s;
  }
  
  @keyframes customSearch {
    from {width: 0;}
    to {width: 50%;}
   
  }
  
  .pagenation_input_Container input{
  
    padding: 15px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  
  .pagenation_container .pagecounter_info{
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  
  .pagecounter_info .activePage div {
    color: #fff;
    width: 20px;
    height: 20px;
    padding: 2px;
    display: flex;
    border-radius: 50%;
    background: #20c997;
    align-items: center;
    justify-content: center;
  }
  
  .pagecounter_info .activePage {
    gap: 10px;
    display: flex;
  }
  
  .noRecord{
    color: red;
    display: flex;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
    align-items: center;
  }
  
  .noRecord p{
    font-size: xx-large;
    color: red !important;
  } 
  
  .pagenationBtn button{
    width: 200px;
    padding: 10px;
    outline: none;
    border: none;
    color: #000;
    border-radius: 10px;
  }
  
  .pagenationBtn button:hover{
    color: #ffffff;
    background: #20c997;
    box-sizing: border-box;
    cursor: pointer;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    box-shadow: 0px 2px 2px 0px #dadada;
  }
  
  
  /* profile */
  
  .profileContainer{
    flex: 1;
  }
  .profileContainer .profileHeader {
    display: flex;
    justify-content: space-between;
  }
  
  .profileContainer .profileHeader .plus {
    width: 40px;
    height: 40px;
    color: #FFF;
    display: flex;
    margin-right: 20px;
    border-radius: 50%;
    align-items: center;
    background: red;
    justify-content: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .profileContainer .profileHeader .trackplus {
    width: 40px;
    height: 40px;
    color: #000;
    display: flex;
    margin-right: 20px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .profileContainer .profileHeader .plus:hover {
    transform: scale(1.2);
  }
  
  .profileContainer main {
    flex: 1;
    display: flex;
    transition: all ease-in 0.3s;
    justify-content: space-between;
    -webkit-transition: all ease-in 0.3s;
  }
   .profileContainer aside{
    top: 0;
    flex: 1;
    z-index: 22;
    position: sticky;
    overflow-y: auto;
    
  }
  .profileContainer .tableContainer{
    top: 0;
    flex: 1;
    height: 70vh;
    z-index: 22;
    position: sticky;
    overflow-y: auto;
    animation-name: TableContainer;
    animation-duration: 0.5s; 
    animation-fill-mode: forwards;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  
  }
  
  @keyframes TableContainer {
    from {flex: 0;}
    to {flex: 1;}
  }
  
  
  .tableContainer .actionIcon svg{
  
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .tableContainer .actionIcon svg:hover{
    transform: scale(1.2);
    color: #20C997 !important;
  }
  .isPaddingLeft {
    padding-left: 10px;
  }
  
  .tableContainer  table thead th {
    text-align: left;
  }
  .profileContainer main table {
    width: 100%;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  .tableContainer table tbody tr{
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  .tableContainer table tbody tr:hover {
    background: #f7f7f7;
  }
  
  .profileWidget {
    height: 70vh;
    padding: 10PX;
    border-radius: 10px;
    animation-name: Profile;
    animation-duration: 0.5s; 
    animation-fill-mode: forwards;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  
  }
  
  .profileWidget .productBtnContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .profileWidget .productBtnContainer button {
    width: 200px;
    border: none;
    padding: 15px;
    font-size: large;
    border-radius: 10px;
    background: #ededed;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
   
  
  
  @keyframes Profile {
    from {flex: 0;}
    to {flex: 1;}
  }
  
  .widgetSection {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .widgetSection .comments {
    flex: 1;
  }
  
  .widgetSection .commentBox {
    top: 0;
    bottom: 0;
    z-index: 22;
    position: sticky;
  }
  .myComment {
    background: #fff;
  }
  .widgetSection .commentBox .inputText {
    width: 100%;
    padding: 10px;
    display: flex;
    background: #fff;
    align-items: center;
    border-radius: 100px;
    justify-content: space-between;
  }
  
  .widgetSection .commentBox .inputText textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 3px;
    background: none;
  }
  
  .widgetSection .commentBox .inputText svg {
    width: 30px;
    color: grey;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .widgetSection .commentBox .inputText svg:hover {
    color: #20c997;
    transform: rotate(90deg);
  }
  
  .inputContainer {
    gap: 5px;
    display: flex;
    justify-content: space-between;
  }
  .inputContainer > div {
    flex: 1;
  }
  
  
  .inputContainer span {
    margin-bottom: 10px;
  }
  .inputContainer > div textarea {
    width: 96%;
    margin: auto;
    padding: 10px;
    outline: none;
    border: none;
    font-size: large;
  }
  
  .inputContainer > div select {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #FFF;
  }
  
  .inputContainer .select select{
    padding: 15px;
    font-size: large;
  }
  
  .inputContainer input{
    padding: 15px;
    width: 90%;
    margin: auto;
    font-size: large;
  }
  
  .button {
    display: flex;
    width: 90px;
    color: #FFF;
    border: none;
    outline: none;
    margin: auto;
    padding: 15px;
    font-size: large;
    border-radius: 10px;
    background: red;
    align-items: center;
    justify-content: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .button:hover {
    transform: scale(1.2);
  }
  
  
  /* funding */
  
  .userCard .cardAvatar{
    gap: 20px;
    display: flex;
    justify-content: space-between;
  
  }
  .userCard .cardAvatar section{
    flex: 1;
  
  }
  .userCard .cardAvatar img{
    width: 200px;
    height: 200px;
    border-radius: 20px;
    object-fit: contain;
  }
  .userCard .cardAvatar .cardHead{
    display: flex;
    justify-content: space-between;
  
  }
  
  .userCard .cardInfo{
    display: flex;
    flex-direction: column;
  }
  .userCard .cardInfo div{
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .userCard .editButton:hover {
    display: flex;
    color: #FFF;
    border: none;
    outline: none;
    margin: auto;
    padding: 3px;
    opacity: .7;
    border-radius: 50%;
    background: red;
    align-items: center;
    justify-content: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  
  .userTitle {
    display: flex;
    justify-content: center;
  }
  
  .searchInput {
    width: 90%;
    display: flex;
    padding: 5px;
    margin: auto;
    border-radius: 25px;
    background: white;
    align-items: center;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  .searchInput:hover {
    background: #ededed;
    transform: scale(1.1);
  }
  .searchInput svg:hover {
    color: #20c997;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
  }
  .searchInput input {
    flex: 1;
    background: none;
  }
  
  .listContainer {
    width: 90%;
    margin: auto;
    padding: 10px;
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    border-bottom: 1.5px solid #f7f7f7;
  }
  
  .listContainer:hover{
    color: #20c997;
    background-color: #f7f7f7;
  }
  
  .modalCloseBtn {
    width: 30px;
    height: 30px;
    padding: 2px;
    color: #F00;
    display: flex;
    margin-top: 5px;
    border-radius: 50%;
    background: #ffff;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 2px 0px #666;
  }
  
  .modal{
    width: 80%;
    margin: auto;
    height: 80%;
    display: flex;
    border-radius: 10px;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
  }
  .modalLarge {
    width: 80%;
    margin: auto;
    height: 80%;
    display: flex;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
  }
  .modalMedium{
    width: 50%;
    margin: auto;
    height: 80%;
    display: flex;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
  }
  .modalSmall{
    width: 25%;
    margin: auto;
    height: 80%;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    animation-name: Modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    transition: ease-in all 0.5s;
    -webkit-transition: ease-in all 0.5s;
    box-shadow: 0px 2px 2px 0px #666;
  }
  @keyframes Modal {
    from {height: 0px;}
    to {height: 80%;}
  }
  /* settings */
  
  .settingsContainer {
    flex: 1;
    width: 90%;
    display: flex;
    margin: auto;
    align-items: center;
    align-items: center;
    flex-direction: column;
  }
  
  .settingsContainer .passwordContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .passwordContainer h3{
    padding: 10px;
    display: flex;
    align-self: center;
  }
  .passwordContainer span {
    margin-left: 10px;
  }
  
  .passwordContainer svg {
    color: #F00;
    width: 60px;
  }
  
  .passwordContainer button {
    width: 50%;
    color: #FFF;
    border: none;
    margin: auto;
    padding: 15px;
    font-size: large;
    background: #F00;
    border-radius: 5px;
    transition: all ease-in 5s;
    -webkit-transition: all ease-in 5s;;
  }
  
  .passwordContainer button:hover{
    transform: scale(1.1);
  }
  
  .settingContainer {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .settingTabs {
    gap: 20px;
    width: 90%;
    padding: 10px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    
  }
  
  .settingTabs button {
    width: 200px;
    border: none;
    padding: 15px;
    margin: auto;
    font-size: large;
    border-radius: 10px;
  }
  
  /* ticket */
  
  .listItem {
    font-size: large;
  }
  
  .listItem .subList {
    font-size: medium;
    margin-left: 20px;
  }
  
  .listItem .subList  {
  
    margin-left: 20px;
  }
  
  .listItem .subList table tr {
  
   transition: all ease-in 0.3s;
   -webkit-transition: all ease-in 0.3s;;
  }
  
  .listItem .subList tr:hover {
    background: #f7f7f7;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px 0px #dadada;
   }
  
  .listItem .subList table tr td svg {
    height: 20px;
    width: 20px;
  }
  
  .list{
    gap: 20px;
    display: flex;
    margin: 10px;
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    align-items: center;
    justify-content: space-between;
    transition: all ease-in 0.3S;
    -webkit-transition: all ease-in 0.3S;
  }
  
  .list .listAvatar {
    border-radius: 50%;
  }
  
  .list .listSquareAvatar svg {
    width: 40px !important;
  }
  
  .list .listSquareAvatar {
    padding: 10px;
    border-radius: 10px;
  }
  
  .list .listText {
    flex: 1;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
  }
  
  .listText h3{
    font-size: medium;
  }
  
  .read {
    color: grey;
  }
  
  ol svg{
    color: gray;
    transition: all ease-in 0.3S;
    -webkit-transition: all ease-in 0.3S;
  }
  
  ol svg:hover{
    color: #20c997;
  }
  
  .list:hover {
    background: #f7f7f7;
  }
  
  .list svg{
    width: 40px;
  }
  
  .productDetailsContainer {
    gap: 10px;
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  
  .productDetailsContainer .flashmsgBox {
    padding: 5px;
    font-size: medium;
    border: 1px dotted #ededed;
  }
  .productDetailsContainer .flashmsgBox p{
    display: flex;
    align-items: center;
    
    justify-content: center;
  }
  .productDetailsContainer .flashmsgBox svg{
    width: 30px;
  }
  .flashmsgBox .img {
    display: flex;
    align-items: center;
  }
  .flashmsgBox img {
    width: 50%;
    margin: auto;
    color: red;
    align-self: center;
    /* display: none; */
  }
  .airtimeCard {
    width: 200px;
    height: 100px;
    padding: 5px;
    display: flex;
    font-size: medium;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: -webkit-linear-gradient(#f00, #ededed);
  }
  
  .airtimeCard .value {
    color: #ffff;
  }
  
  .title {
    padding: 10px;
    font-size: xx-large;
  
  }
  
  .adminEditFormContainer {
    padding: 15px;
    overflow: auto;
  }

  