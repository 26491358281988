@media only screen and (max-width: 600px)  { 
    * {
        font-size: small;
    }
    html, body {
        padding: 0;
    }
    .dashboardLeft_wrapper, .asideBar, .rightWrap {
        display: none;
    }
    .transactionCards {
        flex-direction: column;
    }
    .dashboardCenter, .rightWrap, .mainWrapper, .mainWrapper .centerContainer {
        border-radius: 0;
    }
    .toolTip {
        width: 60%;
        padding: 13px 10px;
        margin-top: 4px;
        position: fixed;
        margin-right: 5px;
        border-radius: 5px;
    }
    .toolTip>div {
        width: 25px;
        height: 25px;
    }
    .toolTip svg {
        width: 20px;
        height: 20px;
    }
    
    .dashboardCenter {
        padding: 0px 10px;
    }
    .mediaScreem {
        display: flex;
    }
    .mediaScreem svg, .lgScreen svg {
        width: 30px;
    }
    .lgScreen {
        display: none !important;
    }
    .dashboardLeft .optionsWrap, .optionsWrap svg {
        color: white;  
    }
    .check_details {
        font-size: small;
    }
    .closeIcon {
        width: 30px;
        color: white;
    }
    .orangeBorder {
        height: 0;
        top: 17px;
    }
    .searchWrap {
        display: none;
    }
    .componentName {
        flex: 1;
    }
    .airtimeContWrap, .dataContWrap {
        padding: 0;
    }
    .airtimeMain, .dataMain {
        display: flex;
        flex-direction: column;
    }
    .dataLeftWrap, .airtimeLeftWrap {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .productContainer {
        grid-template-columns: repeat(3, 1fr);
        padding: 0 5px;
    }
    
    .airtimeRight  {
        flex: 1;
        padding: 0;
    }

    .dataItem {
        padding: 10px 5px;
        border-radius: 5px;
        align-items: center;
        border: 1px solid gray;
    }

    .dataItem p, .dataLeft p {
        font-size: smaller !important;
    }
    .dataItem svg {
        margin: 2px;
        width: 18px !important;
    }
    .airtimeLCont .rechargeImg {
        width: 40px;
        height: 40px;
    }
    .transactionMain {
        gap: 10px;
        margin: 20px auto;
        grid-template-columns: repeat(2, 1fr);
    }
    .profDetails {
        width: 100%;
    }
    .airtimeLeft, .dataLeft {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 0;
        transition: none;
        -webkit-transition: none;
    }

    .activeTxt {
        display: none;
    }

    .profileGrowth{
        display: none;
    }
    .logOutWrapper {
        flex: 1;
        bottom: 80px;
    }
    
    
}