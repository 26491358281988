* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body {
    width: 100%;
    height: 100%;
    display: grid;
    background-color: #fafcff;
    font-family: Arial, Helvetica, sans-serif;
}
.blueHeader {
    padding: 22px;
}
.login_wrapper {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.welcome_txt {
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    letter-spacing: -1px;
}
.comp_logo {
    width: 300px;
    display: flex;
    justify-content: center;
}
.comp_logo img {
    width: 100%;
   object-fit: contain;
}
.login_form {
    width: 30%;
    padding: 10px;
}
.login_inpt {
    margin: 14px auto;
}
.login_inpt input {
    width: 100%;
    outline: none;
    padding: 15px;
    color: #002147;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: 0.3s all ease-in;
    background-color: transparent;
    -webkit-transition: 0.3s all ease-in;
}
.login_inpt input:focus {
    box-shadow: 3px 6px 6px -6px rgba(0, 33, 71, 0.3);
}
.login_inpt input::placeholder {
    color: rgba(0, 33, 71, 0.7);
}
.check_details {
    width: 99%;
    margin: auto;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}
.check_cont {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 10fr;
}
.check_details input {
    display: block;
    cursor: pointer;
}
.forgot_pass {
    text-align: right;
}
._btn {
    margin: 14px auto;
}
._btn button {
    width: 100%;
    border: none;
    outline: none;
    color: #fff;
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.4s all ease-in-out;
    -webkit-transition: 0.4s all ease-in-out;
    background-image: linear-gradient(90deg, #002147, #003572, #004594);
}
._btn button:hover {
    transform: scaleX(0.99);
    background-image: linear-gradient(90deg, #002147, #002147, #002147);
}
.no_accnt {
    margin: 10px auto;
    text-align: center;
}
.no_accnt span {
    cursor: pointer;
    font-size: 14px;
    color: #002147;
    margin-left: 5px;
    font-weight: bold;
    transition: 0.4s all ease-in-out;
    -webkit-transition: 0.4s all ease-in-out;
}
.no_accnt span:hover {
    color: #FF6700; 
    transform: scaleX(2);
}
.arrow {
    font-size: 18px;
    font-weight: 800;
}
.copyright {
    left: 25%;
    width: 50%;
    bottom: 1vw;
    font-size: 13px;
    color: #002147;
    text-align: center;
    position: absolute;
}

@media only screen and (max-width: 600px)  {
    .blueHeader {
        display: none;
    }
    .comp_logo {
        width: 200px;
        margin: 0 auto;
        position: relative;
        top: 75%;
    }
    .login_form {
        width: 90%;
        margin: 0 auto;   
        padding: 0;
    }
    .login_wrapper {
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr;
    }
    .no_accnt {
        position: relative;
        bottom: 30%;
        margin: 0;
    }
    .copyright {
        bottom: 10%;
    }
    
}